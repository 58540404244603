// USER ACTION CONTENT
.popover-row-hover {
  height: 40px;
  min-width: 100px;
  font-size: 18px;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.6);

  &:hover {
    background-color: #c7c7c7;
    color: #fff !important;
    border-radius: 5px;
  }
  .left {
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
}
.header {
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
    // direction: rtl;
  }
}
