@import "./base/app";
@import "./components/header";

.event-status-0 {
  background-color: #3c64af81;
  & .fc-event-time,
  & .fc-event-title {
    color: #073384;
    font-weight: 500;
  }
  &:hover {
    background-color: #3c64af81;
  }
}
.event-status-future {
  background-color: rgba(200, 200, 200, 0.7);
  & .fc-event-time,
  & .fc-event-title {
    color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    background-color: rgba(200, 200, 200, 0.7);
  }
}
.event-status-past {
  background-color: rgba(200, 200, 200, 0.7);
  & .fc-event-time,
  & .fc-event-title {
    color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    background-color: rgba(200, 200, 200, 0.7);
  }
}
.event-status-1 {
  background-color: #9f18236e;
  & .fc-event-time,
  & .fc-event-title {
    color: #931620;
    font-weight: 500;
  }
  &:hover {
    background-color: #9f18236e;
  }
}

.event-status-2 {
  background-color: #d84c997a;
  & .fc-event-time,
  & .fc-event-title {
    color: #9d1584;
    font-weight: 500;
  }
  &:hover {
    background-color: #d84c997a;
  }
}

.event-status-3 {
  background-color: #266b272f;
  & .fc-event-time,
  & .fc-event-title {
    color: #22752d;
    font-weight: 500;
  }
  &:hover {
    background-color: #266b272f;
  }
}

.fc-event-time {
  display: none !important;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc .fc-popover {
  max-width: 500px;
}

.fc-daygrid-day-top {
  justify-content: center;
}
