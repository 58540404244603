#root,
html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

.booked-list {
  & .att-collapse-content-box {
    padding-block: 0px !important;
  }
}

.ql-container.ql-snow {
  height: 700px;
}
.auth-page-content {
  width: 100% !important;
  height: 100%;
}
.auth-page-content .auth-left-bg {
  background: #f8f8f8;
  height: 99vh;
}

.auth-page-content .auth-left-bg img {
  height: 100%;
}

.auth-page-content .auth-tight h5 {
  font-size: 1.714rem;
  line-height: 1.2;
  color: #5e5873;
}

.auth-page-content .logo-image {
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 1;
}

.loading_page {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #7c797945;
  z-index: 99;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 3px;
}

.ant-form-vertical .ant-form-item-label label {
  color: #5e5873;
}

.ant-input-affix-wrapper {
  border-radius: 2px;
}
.ant-form-item {
  margin-bottom: 15px;
}

.modal .modal-header {
  border-bottom: 1px solid #eee;
  padding: 10px 25px;
  background: #eee;
}

.ant-message {
  z-index: 999999;
}

.ant-input-suffix {
  margin-left: 4px;
  position: absolute;
  right: 10px;
  top: 8px;
}

.ant-pagination-item-link .anticon {
  margin-top: 10px;
}

.ant-message .anticon {
  top: 12px;
}

.auth-page-content .auth-tight {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.ant-form-item-control-input-content .ant-picker {
  width: 100%;
  padding: 4px 11px !important;
}

.ant-message-notice-content {
  padding-top: 2px;
}

.input-group .custom-file {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
  height: 44px;
}

.input-group .custom-file input {
  width: 100%;
  height: 45px;
  line-height: 36px;
  display: block;
}

.show_data_import .left_item h3,
.show_data_import .right_item h3 {
  font-size: 16px;
  color: #000;
  padding-bottom: 7px;
  border-bottom: 1px solid #eee;
}

.show_data_import .left_item ul li,
.show_data_import .right_item ul li {
  width: 100%;
  border-bottom: 1px solid #eee;
  list-style: none;
  padding: 3px 5px;
}

.show_data_import .left_item ul,
.show_data_import .right_item ul {
  padding: 0px;
}

.show_data_import .right_item ul li span {
  float: right;
}

.show_data_import .right_item ul li .success {
  color: rgb(26, 250, 26);
  font-size: 20px;
}

.show_data_import .right_item ul li .error {
  color: rgb(218, 20, 6);
  font-size: 20px;
}

.show_data_import .left_item ul li:nth-of-type(odd),
.show_data_import .right_item ul li:nth-of-type(odd) {
  background: #f3f3f3;
}

.input-group .custom-file label {
  background: #dbdbdb;
  padding: 10px 12px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  z-index: 999;
  top: 1px;
  width: 93px;
  text-align: center;
}

.ant-btn-loading span {
  margin-top: 0;
  float: right;
}

.ant-modal-wrap {
  z-index: 99999;
}

.ant-modal-mask {
  z-index: 9999;
}

.ant-select-dropdown {
  z-index: 99999;
}

.auth-page-content .invalid {
  color: red;
}

.auth-page-content form input,
.auth-page-content .ant-input-affix-wrapper input {
  padding: 8px 15px;
  border-radius: 5px;
}

.auth-page-content .ant-input-affix-wrapper {
  padding: 0px;
}

.auth-page-content form button.ant-btn-default {
  height: 42px;
  border-radius: 5px;
  background: #8c1ef1;
  color: #fff;
  font-weight: bold;
}

.auth-page-content .ant-form-item .ant-form-item-row {
  display: block;
}

.ant-table-content {
  overflow-x: scroll;
}
.ant-table-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}
.ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.ant-card-body {
  padding: 15px;
}
.ant-modal-close .ant-modal-close-x {
  background: #eee;
}
.ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
  margin-top: 20px;
}
.ant-tooltip {
  z-index: 99999;
}
.custom-file .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}
.ant-drawer-header-title .ant-drawer-close {
  position: absolute;
  left: 10px;
  top: 17px;
  padding-right: 20px;
}
.ant-drawer-header-title .ant-drawer-title {
  padding-left: 25px;
}
.ant-drawer {
  z-index: 1001;
}
.ant-picker-dropdown-range {
  z-index: 99999;
}
.text-left {
  text-align: left;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  text-align: left;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  left: 15px;
  position: absolute;
}
.ant-btn .ant-btn-loading-icon {
  float: left;
  padding-top: 3px;
}
.ant-btn.ant-btn-loading {
  min-width: 90px;
  width: max-content;
}
@media (max-width: 768px) {
  .d-none-sm {
    display: none;
  }
  .navbar-header {
    margin-top: 0px;
  }
  [data-layout="vertical"] .navbar-brand-box {
    display: block;
  }
  .navbar-brand-box.horizontal-logo {
    display: none;
  }
  .main-content {
    margin-top: 15px;
  }
  .form-button .ant-space {
    display: inline;
  }
  .form-button .ant-space-item {
    float: left;
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
a.button-show-infomation {
  color: rgba(0, 0, 0, 0.85);
}

a.button-show-infomation:hover {
  text-decoration: underline;
}
